import { h, Component, Fragment } from 'preact'
import { router } from '@ruiyun/preact-m-router'
import {
  RowView,
  ColumnView,
  SlotRowView,
  XCenterView,
} from '@ruiyun/preact-layout-suite'
import Line from '@ruiyun/preact-line'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import SafeGet from 'dlv'
import Page from '@ruiyun/preact-m-page'
import Button from '@ruiyun/preact-button'

import SectionTitle from '../../components/SectionTitle'

import { getCurrentUserInfo, logout } from '../../services/UserService'

const Cell = ({ label, value, icon, onClick, slot }) => (
  <RowView
    height={100}
    padding={[0, 30, 0, 30]}
    hAlign='between'
    onClick={onClick}
  >
    <Text>{label}</Text>
    {value && <Text color='#666'>{value}</Text>}
    {icon && <Icon name={icon} color='#888' size={22} />}
    {slot && slot()}
  </RowView>
)

export default class Detail extends Component {
  state = {
    accountId: '',
    name: '',
    phone: '',
    email: '',
    area: '',
    department: '',
    products: [],
    reportAccount: null,
  }

  onTabShow = () => {
    router.setTitle('个人中心')
  }

  async componentDidMount() {
    router.setTitle('个人中心')
    const { success, data } = await getCurrentUserInfo()
    if (success) {
      console.log(data)
      this.setState({
        accountId: data.accountId,
        name: data.cnName,
        phone: data.companyMobile,
        email: data.companyEmail,
        department: SafeGet(data, 'department.name'),
        products: [SafeGet(data, 'product.name')],
        reportAccount: data.reportAccount,
      })
    }
  }

  render() {
    const {
      name,
      phone,
      email,
      area,
      department,
      products,
      reportAccount,
    } = this.state
    return (
      <Page>
        <Page.Content>
          <ColumnView bgColor='#fff'>
            <SectionTitle>个人信息</SectionTitle>
            <Cell label={<span id='debugBtn'>姓名</span>} value={name} />
            <Line />
            <Cell label='手机' value={phone} />
            <Line />
            <Cell label='邮箱' value={email} />
            <Line />
            <Cell label='省市' value={area} />
            <Line />
            <Cell label='部门' value={department} />
            <Line />
            <Cell label='负责产品' value={products.join('、')} />
            {reportAccount && (
              <Fragment>
                <Line />
                <Cell
                  label='直属上级'
                  slot={() => (
                    <SlotRowView slot={20} height={42}>
                      <Text color='#666'>{reportAccount.cnName}</Text>
                      <Line v color='#ccc' indent={[5, 5]} />
                      <Text color='#666'>{reportAccount.companyMobile}</Text>
                    </SlotRowView>
                  )}
                />
              </Fragment>
            )}
          </ColumnView>
          <XCenterView height={200}>
            <Button width={690} height={88} color='#f8584f' onClick={logout}>
              登出
            </Button>
          </XCenterView>
          <XCenterView height={100}>
            <Text size={26}>
              <a href='tel:4000501717' style={{ color: '#2074e3' }}>
                联系我们
              </a>
            </Text>
          </XCenterView>
        </Page.Content>
      </Page>
    )
  }
}
