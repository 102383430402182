import { h } from 'preact'
import { useState, useCallback } from 'preact/compat'
import {
  SlotColumnView,
  XCenterView,
  SpaceHolder,
} from '@ruiyun/preact-layout-suite'
import { router } from '@ruiyun/preact-m-router'
import Spin from '@ruiyun/preact-loading'
// import dlv from 'dlv'
import { WithActionSheet } from '@ruiyun/preact-m-actionsheet'

import { fetchOrderList } from '../../services/CarService'
import OrderListCard from './orderListCard'
import MeetingInfoCard from './meetingInfoCard'

const Loading = () => (
  <XCenterView>
    <Spin />
  </XCenterView>
)
const MeetingListItem = ({
  id,
  name,
  status,
  startTime,
  endTime,
  hotel,
  address,
  budgets,
  bmsBizStatus,
  $actionsheet,
  bmsMeetingHotel,
  subStatus,
}) => {
  // const [limit, updateLimit] = useState(3)
  const [fold, updateFold] = useState(false)
  const [orders, updateOrders] = useState([])
  // 订单加载中
  const [loadingOrders, updateLoadingOrders] = useState(false)

  const onToggle = useCallback(async () => {
    updateFold(!fold)
    if (!fold) {
      console.log('fetch list')
      updateLoadingOrders(true)
      const { success, data } = await fetchOrderList({
        pageSize: 999,
        pageNum: 1,
        refId: id,
        specialQuery: 'SPECIAL_1',
      })
      updateLoadingOrders(false)
      if (success) {
        updateOrders(data.data)
      }
    }
  }, [updateFold, fold, id])

  const gotoOrderDetail = useCallback(
    (orderId) => {
      updateFold(false)
      router.push('carOrderDetail', { orderId, meetingId: id })
    },
    [router]
  )

  const onCallCar = useCallback(() => {
    $actionsheet('选择用车类型', ['网约车', '线下车队(需提前24小时)']).then(
      (index) => {
        setTimeout(() => {
          updateFold(false)
          router.push(['taxi', 'convoy'][index], {
            meetingId: id,
            budgetId: budgets.items[0].id,
            startTime,
            endTime,
            position: bmsMeetingHotel?.position,
          })
        }, 300)
      }
    )
  }, [router])

  return (
    <div>
      <SlotColumnView bgColor='#fff' padding={[30, 30, 30, 30]} slot={10}>
        <MeetingInfoCard
          fold={fold}
          syncFold={updateFold}
          onToggleFold={onToggle}
          id={id}
          name={name}
          status={status}
          startTime={startTime}
          endTime={endTime}
          budgets={budgets}
          bmsBizStatus={bmsBizStatus}
          $actionsheet={$actionsheet}
          bmsMeetingHotel={bmsMeetingHotel}
          subStatus={subStatus}
          onCallCar={onCallCar}
        />
        {fold &&
          (loadingOrders ? (
            <Loading />
          ) : (
            <OrderListCard orders={orders} gotoOrderDetail={gotoOrderDetail} />
          ))}
      </SlotColumnView>
      <SpaceHolder height={20} />
    </div>
  )
}

export default WithActionSheet(MeetingListItem)
