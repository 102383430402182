import { router } from '@ruiyun/preact-m-router'
import { TOKEN_KEY } from '../constants/other'
import { GraphQLClient } from '../libs/request'

import { SEND_CODE, LOGIN, CURRENT_USER } from '../constants/gql'

export const getCAPTCHA = (phone) =>
  GraphQLClient.gql(SEND_CODE, { mobile: phone }, false, 'all')

export const login = ({ phone, code, sessionId }) =>
  GraphQLClient.gql(LOGIN, {
    mobile: phone + '',
    code: code + '',
    sessionId,
  })

export const logout = () => {
  window.localStorage.setItem(TOKEN_KEY, '')
  router.replace('login')
}

export const getCurrentUserInfo = () => GraphQLClient.gql(CURRENT_USER)
