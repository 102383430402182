class FakeDebug {
  constructor(props) {
    const {
      key = '___FAKE-DEBUG___',
      selector,
      maxClickCount = 3,
      maxDistance = 1000,
    } = props || {}
    this.maxClickCount = maxClickCount
    this.maxDistance = maxDistance
    this.localStorageKey = key
    this.selector = selector
  }

  addScript = () => {
    const cdnDom = document.createElement('script')
    cdnDom.src = 'https://cdn.bootcss.com/vConsole/3.3.4/vconsole.min.js'
    cdnDom.onload = function (params) {
      const scriptDom = document.createElement('script')
      const vconsoleHtml = `
        // init vConsole
        var vConsole = new VConsole();
        `
      scriptDom.innerHTML = vconsoleHtml
      document.body.appendChild(scriptDom)
    }
    document.body.appendChild(cdnDom)
  }

  addCss = (code) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.rel = 'stylesheet'
    style.appendChild(document.createTextNode(code))
    var head = document.getElementsByTagName('head')[0]
    head.appendChild(style)
  }

  isOpenDebug = () =>
    window.localStorage.getItem(this.localStorageKey) === 'true'

  closeDebug = () => {
    window.localStorage.setItem(this.localStorageKey, '')
    this.addCss(`#__vconsole{display:none;}`)
  }

  openDebug = () => {
    window.localStorage.setItem(this.localStorageKey, 'true')

    if (!window.vConsole) {
      this.addScript()
    } else {
      this.addCss(`#__vconsole{display:block;}`)
    }
  }

  /**
   * 连续点击开启
   * @param {*} open
   * @param {*} close
   * TODO
   * 响应点击次数在移动端禁止缩放的情况下是有问题的
   * 每连续两次touch实际上只响应了触发了一次
   * 比如：连续快速点击两下是触发一次，三下、四下实际上是触发两次，五下才能触发三次
   */
  addMultiClickTrigger = (open, close) => {
    if (this.selector) {
      let lastClickTime = 0
      let timer = null
      let clickCount = 0
      document.addEventListener('touchstart', (event) => {
        if (event.target.id === this.selector) {
          const nowTime = new Date().getTime()
          // 连续点击状态下 清除上次timer
          if (clickCount > 0) {
            timer && clearTimeout(timer)
          }

          // 有效点击次数加1
          clickCount++

          if (
            nowTime - lastClickTime < 400 &&
            clickCount >= this.maxClickCount
          ) {
            lastClickTime = 0
            clickCount = 0
            console.log('多次点击')
            if (this.isOpenDebug()) {
              close && close()
            } else {
              open && open()
            }
          } else {
            lastClickTime = nowTime
            timer = setTimeout(() => {
              clickCount = 0
              console.log('单击')
            }, 400)
          }
        }
      })
    }
  }

  computePosition = (startPoint, currentPoint) => {
    const xDistance = currentPoint.clientX - startPoint.clientX
    const yDistance = currentPoint.clientY - startPoint.clientY
    return {
      angle: (Math.atan(xDistance / yDistance) * 180) / Math.PI,
      xDistance,
      yDistance,
    }
  }

  /**
   * 滑动距离开启
   * @param {*} open
   * @param {*} close
   */
  addTouchMoveTrigger = (open, close) => {
    let lastPoint = null
    let moveDistance = 0
    if (this.selector) {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.target.id === this.selector) {
            if (lastPoint) {
              const { xDistance, yDistance } = this.computePosition(
                lastPoint,
                event.touches[0]
              )
              moveDistance += Math.abs(xDistance) + Math.abs(yDistance)
            }
            lastPoint = event.touches[0]
          }
        },
        { passive: true }
      )
      document.addEventListener(
        'touchend',
        (event) => {
          if (event.target.id === this.selector) {
            if (moveDistance >= this.maxDistance) {
              if (this.isOpenDebug()) {
                close && close()
              } else {
                open && open()
              }
            }
            lastPoint = null
            moveDistance = 0
          }
        },
        { passive: true }
      )
    }
  }

  start = () => {
    // 每次start判断是否需要开启debug
    if (this.isOpenDebug() && !window.vConsole) {
      this.openDebug()
    } else if (!this.isOpenDebug() && window.vConsole) {
      this.closeDebug()
    }
    this.addMultiClickTrigger(this.openDebug, this.closeDebug)
    // this.addTouchMoveTrigger(this.openDebug, this.closeDebug)
  }
}
export default FakeDebug
