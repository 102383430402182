import { h } from 'preact'
import TabBar from '@ruiyun/preact-m-tabbar'
import { ColumnView } from '@ruiyun/preact-layout-suite'

import Home from './home'
import User from './user'
import Meeting from './meeting'

import FixedContactCustomer from '../../components/FixedContactCustomer'
const tabbarConfig = {
  options: [
    {
      text: '首页',
      icon: 'iconshouye',
    },
    {
      text: '会议',
      icon: 'iconhuiyi',
    },
    {
      text: '我的',
      icon: 'iconwode',
    },
  ],
  color: '#ccc',
  activeColor: '#5581FA',
  textSize: 18,
  iconSize: 40,
}

const Index = () => (
  <ColumnView height='100vh'>
    <TabBar config={tabbarConfig}>
      <Home />
      <Meeting />
      <User />
    </TabBar>
    <FixedContactCustomer />
  </ColumnView>
)

export default Index
