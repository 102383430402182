let BASE_URL
let BASE_GRAPHQL_URL
// eslint-disable-next-line
if ($BUILD_TARGET$ === 'production') {
  BASE_URL = 'https://mapi.shangyantong.com/baey'
  BASE_GRAPHQL_URL = 'https://uplatform-graphql.quancheng-ec.com'
} else {
  BASE_URL = 'https://uapi.dev.quancheng-ec.com/baey'
  BASE_GRAPHQL_URL = 'https://bms-uplatform.dev.quancheng-ec.com'
}

export const MEETING_URL = BASE_URL + '/meetings'
export const GRAPHQL_URL = BASE_GRAPHQL_URL + '/graphql'
export const FETCH_CAR_ORDER_TRACE = BASE_URL + '/api/travel/order/track'
export const FETCH_DRIVE_POSITION = BASE_URL + '/api/travel/order/position'
export const QUERY_CAR_ESTIMATE = BASE_URL + '/api/travel/estimate'
export const CREATE_CAR_ORDER = BASE_URL + '/api/travel/order/create'
export const CREATE_CAR_ORDER_CHECK =
  BASE_URL + '/api/travel/order/create/check'
export const FETCH_CAR_ORDER_LIST = BASE_URL + '/api/travel/order/list'
export const CREATE_MULTI_CAR_ORDER =
  BASE_URL + '/api/travel/order/create/multi/supplier'
export const QUERY_CAR_ORDER_DETAIL = BASE_URL + '/api/travel/order/detail'
export const CANCEL_CAR_ORDER = BASE_URL + '/api/travel/order/cancel'
export const QUERY_FLIGHT_INFO = BASE_URL + '/api/travel/flight/info'
