import { h } from 'preact'
import {
  RowView,
  SlotRowView,
  SlotColumnView,
} from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import dayjs from 'dayjs'

import {
  CAR_ORDER_STATUS_MAP,
  CAR_MODEL_MAP,
  SUPPLIER_MAP,
} from '../../constants/other'

function alternateInsert(arr, item) {
  const insertedArr = arr.reduce(
    (all, current) => (current ? all.concat(current, item) : all),
    []
  )
  insertedArr.pop()
  return insertedArr
}

const serviceTypeMap = {
  now: '立即用车',
  reserve: '预约用车',
  drop_off: '送机',
  pick_up: '接机',
  rent: '包车',
}
const OrderListCard = ({ orders, gotoOrderDetail }) => (
  <SlotColumnView
    padding={[30, 30, 30, 30]}
    bgColor='#f4f4f4'
    slot={30}
    style={{ borderRadius: '0.2rem' }}
  >
    {orders?.map(({ orderId, serviceType, status, schedules, supplier }) => (
      <SlotColumnView
        key={orderId}
        slot={8}
        onClick={() => gotoOrderDetail(orderId)}
      >
        <RowView hAlign='between'>
          <SlotRowView slot={10}>
            <Icon name='iconwangyueche' color='#5581FA' size={26} />
            <Text size={26} weight={400}>
              {alternateInsert(
                [
                  serviceTypeMap[serviceType],
                  CAR_MODEL_MAP[schedules[0]?.model]?.text,
                  SUPPLIER_MAP[supplier]?.text,
                ],
                '-'
              )}
            </Text>
          </SlotRowView>

          <Text size={22} color={CAR_ORDER_STATUS_MAP[status]?.color || ''}>
            {CAR_ORDER_STATUS_MAP[status]?.text || ''}
          </Text>
        </RowView>
        <SlotRowView slot={10}>
          <Icon name='iconshijian' size={24} color='#ccc' />
          <Text size={24} color='#666666'>
            {dayjs(schedules[0].departureTime).format('MM月DD日 HH:mm')}
          </Text>
          <Icon name='iconren' size={24} color='#ccc' />
          <Text size={24} color='#666666'>
            {schedules[0].passenger.name}
          </Text>
        </SlotRowView>
      </SlotColumnView>
    ))}
  </SlotColumnView>
)

export default OrderListCard
