export let TOKEN_KEY

// eslint-disable-next-line
if ($BUILD_TARGET$ === 'production') {
  TOKEN_KEY = 'bms-h5-pro'
} else {
  TOKEN_KEY = 'bms-h5-dev'
}

export const TMAP_KEY = 'YFFBZ-LHRKX-X4G4J-7V7GV-XPOVZ-IUFIP'

export const CAR_ORDER_STATUS_MAP = {
  PF_TRAVEL_DISPATCHING: {
    text: '派车中',
    color: '#ee7700',
  },
  PF_TRAVEL_DISPATCHED: {
    text: '派车成功',
    color: '#ee7700',
  },
  PF_TRAVEL_FAILED_IN_DISPATCHING: {
    text: '派车失败',
    color: '#f8584f',
  },
  PF_TRAVEL_DRIVER_ARRIVED: {
    text: '司机已到达',
    color: '#ee7700',
  },
  PF_TRAVEL_SERVING: {
    text: '司机已接车（服务中)',
    color: '#ee7700',
  },
  PF_TRAVEL_TO_BE_CONFIRMED: {
    text: '待消费确认',
    color: '#ee7700',
  },
  PF_TRAVEL_FINISHED: {
    text: '已完成',
    color: '#51a04c',
  },
  PF_TRAVEL_CANCELED: {
    text: '已取消',
    color: '#ccc',
  },
  PF_TRAVEL_CANCELED_TO_BE_CONFIRMED: {
    text: '已取消',
    color: '#ccc',
  },
  PF_TRAVEL_CANCELED_WITH_COST: {
    text: '已取消',
    color: '#ccc',
  },
}

export const CAR_ORDER_CANCELABLE_STATUS = [
  'PF_TRAVEL_DISPATCHING',
  'PF_TRAVEL_DISPATCHED',
  'PF_TRAVEL_DRIVER_ARRIVED',
]

export const SUPPLIER_MAP = {
  shenzhou: {
    text: '神州',
  },
  didi: {
    text: '滴滴',
  },
  offlineteam: {
    text: '线下车队',
  },
}

export const CAR_MODEL_MAP = {
  comfortable: {
    text: '舒适型',
  },
  business: {
    text: '商务型',
  },
}
