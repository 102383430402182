import { h, Fragment } from 'preact'
import {
  RowView,
  SlotRowView,
  SlotColumnView,
  XCenterView,
} from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import Icon from '@ruiyun/preact-icon'
import dayjs from 'dayjs'
// import dlv from 'dlv'
import Button, { TextButton } from '@ruiyun/preact-button'

const IconTextCell = ({ icon, text }) => (
  <SlotRowView slot={12}>
    <Icon
      name={icon}
      size={26}
      color='#ccc'
      style={{ alignSelf: 'flex-start', marginTop: '2px' }}
    />
    <Text color='#888' size={26}>
      {text}
    </Text>
  </SlotRowView>
)
const MeetingInfoCard = ({
  id,
  name,
  status,
  startTime,
  endTime,
  budgets,
  bmsBizStatus,
  $actionsheet,
  bmsMeetingHotel,
  subStatus,
  fold,
  syncFold,
  onToggleFold,
  onCallCar,
}) => {
  // 开放叫车时间 只要会议处在 执会中 可叫车
  const canCallCar = status === 'U_MEETING_IN_PROGRESS' && subStatus === null
  return (
    <Fragment>
      <RowView hAlign='between'>
        <Text weight={400}>{name}</Text>
        <Text size={24} color={bmsBizStatus?.color}>
          {bmsBizStatus?.text}
        </Text>
      </RowView>
      <SlotColumnView slot={5}>
        <IconTextCell
          icon='iconshijian'
          text={`${dayjs(startTime).format('MM月DD日')} -
  ${dayjs(endTime).format('MM月DD日')}`}
        />
        <IconTextCell icon='iconjiudian' text={bmsMeetingHotel?.name} />
        <IconTextCell
          icon='icondingwei'
          text={bmsMeetingHotel?.address || '-'}
        />
      </SlotColumnView>
      {status === 'U_MEETING_IN_PROGRESS' && (
        <XCenterView style={{ position: 'relative' }}>
          {canCallCar && (
            <Button
              width={180}
              height={58}
              borderColor='#5581FA'
              textColor='#5581FA'
              borderRadius={29}
              textSize={24}
              onClick={onCallCar}
            >
              叫车
            </Button>
          )}
          <XCenterView
            height='100%'
            style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 2 }}
          >
            <TextButton
              textSize={22}
              textColor='#5581FA'
              onClick={onToggleFold}
            >
              {fold ? '收起' : '订单'}
              &ensp;
              <Icon
                name={
                  fold ? 'iconjiantou-copy-copy-copy' : 'iconiconfontjiantou'
                }
                color='#5581FA'
                size={20}
              />
            </TextButton>
          </XCenterView>
        </XCenterView>
      )}
    </Fragment>
  )
}
export default MeetingInfoCard
