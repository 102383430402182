import { GraphQLClient } from '../libs/request'

import { QUERY_MEETINGS } from '../constants/gql'

export const queryMeetings = ({ pageSize, pageNum, keyword, status }) =>
  GraphQLClient.gql(
    QUERY_MEETINGS,
    {
      search: {
        pager: {
          currentPage: pageNum,
          pageSize,
        },
        query: {
          name: keyword,
          status,
        },
      },
    },
    false,
    'all'
  )
