import { h, Component, Fragment } from 'preact'
import AutoList from '@ruiyun/preact-m-auto-list'
import { ColumnView, XCenterView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'
import { router } from '@ruiyun/preact-m-router'
import { queryMeetings } from '../../services/MeetingService'

import ListItem from '../../components/MeetingListItem'

const keyExtractor = (item) => `${item.id}-${item?.ts}`

const format = (ret) => {
  return {
    list: ret.data.items.map((item) => {
      return {
        ...item,
        ts: Date.now(),
      }
    }),
    nomore: ret.data.pager.currentPage >= Math.ceil(ret.data.pager.total / 20),
  }
}

const renderItem = (props) => <ListItem {...props} />

export default class Home extends Component {
  componentDidMount() {
    router.setTitle('Bristol-Myers Squibb')
  }

  onTabShow = () => {
    router.setTitle('Bristol-Myers Squibb')
  }

  render() {
    return (
      <Fragment>
        <XCenterView
          width='100vw'
          height={80}
          bgColor='#ffffff'
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 9 }}
        >
          <Text size={26} color='#60C8AD'>
            欢迎使用BMS会议用车平台
          </Text>
        </XCenterView>
        <ColumnView padding={[80, 0, 0, 0]}>
          <AutoList
            refreshable
            pageSize={20}
            fetchListData={queryMeetings}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            format={format}
            params={{ status: 'IN_PROGRESS' }}
          />
        </ColumnView>
      </Fragment>
    )
  }
}
