import { h } from 'preact'
import { XCenterView } from '@ruiyun/preact-layout-suite'
import Icon from '@ruiyun/preact-icon'
import { getWechatWx } from '@ruiyun/platform-env'
const FixedContactCustomer = () => {
  const handleClick = () => {
    getWechatWx().then((wx) => {
      wx.miniProgram.navigateTo({
        url: '/pages/contactCustomer/index',
      })
    })
  }
  return (
    <XCenterView
      bgColor='#fff'
      width={60}
      height={60}
      style={{
        borderRadius: '50%',
        position: 'fixed',
        right: '10vw',
        bottom: '15vh',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        zIndex: 99,
      }}
      onClick={handleClick}
    >
      <Icon name='iconkefutubiao' color='rgb(85, 129, 250)' size={36} />
    </XCenterView>
  )
}
export default FixedContactCustomer
