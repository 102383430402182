import { JavaRequestClient, useJavaRequestClient } from '../libs/request'

import {
  FETCH_CAR_ORDER_TRACE,
  QUERY_CAR_ESTIMATE,
  CREATE_CAR_ORDER,
  CREATE_MULTI_CAR_ORDER,
  FETCH_CAR_ORDER_LIST,
  QUERY_CAR_ORDER_DETAIL,
  CANCEL_CAR_ORDER,
  QUERY_FLIGHT_INFO,
  CREATE_CAR_ORDER_CHECK,
  FETCH_DRIVE_POSITION,
} from '../constants/apis'
export const fetchOrderCarTrace = ({ driveId }) =>
  JavaRequestClient.post(
    FETCH_CAR_ORDER_TRACE,
    {
      id: driveId,
    },
    false
  )
export const fetchDrivePosition = ({ driveId, destination }) =>
  JavaRequestClient.post(
    FETCH_DRIVE_POSITION,
    {
      id: driveId,
      destination,
    },
    false
  )

export const queryEstimate = async ({
  from,
  to,
  serviceType,
  departureTime,
  rentType,
  originCity,
}) => {
  if (from && serviceType) {
    const rets = await Promise.all(
      ['comfortable', 'business'].map((model) =>
        JavaRequestClient.post(
          QUERY_CAR_ESTIMATE,
          {
            originLat: from.latitude,
            originLng: from.longitude,
            destinationLat: to && to.latitude,
            destinationLng: to && to.longitude,
            serviceType,
            model,
            departureTime,
            rentType,
            flightDepartDelayTime: 10,
            originCity,
          },
          false
        )
      )
    )
    if (rets.every((r) => r.success)) {
      return {
        success: true,
        data: rets.map((r) => r.data),
      }
    } else {
      return {
        success: false,
        error: rets.find((r) => !r.success).error,
      }
    }
  }
}

export const createOrder = (params) =>
  JavaRequestClient.post(CREATE_CAR_ORDER, params)

export const cancelOrder = (orderId) =>
  JavaRequestClient.post(CANCEL_CAR_ORDER, {
    orderId,
    force: true,
    reason: '',
  })

export const queryOrderDetail = (orderId) =>
  JavaRequestClient.post(QUERY_CAR_ORDER_DETAIL, { orderId })

export const createMultiOrder = (params) =>
  JavaRequestClient.post(CREATE_MULTI_CAR_ORDER, params)

export const fetchOrderList = ({ pageSize, pageNum, ...otherProps }) =>
  JavaRequestClient.post(
    FETCH_CAR_ORDER_LIST,
    {
      page: {
        currentPage: pageNum,
        pageSize,
      },
      ...otherProps,
    },
    false
  )

export const getFlightInfo = (flightDate, flightNum) =>
  JavaRequestClient.post(
    QUERY_FLIGHT_INFO,
    {},
    { params: { flightDate, flightNum } }
  )

export const useFlightInfo = useJavaRequestClient(QUERY_FLIGHT_INFO, 'get')

export const createOrderCheck = (params) =>
  JavaRequestClient.post(CREATE_CAR_ORDER_CHECK, params)
