import { h } from 'preact'
import { RowView } from '@ruiyun/preact-layout-suite'
import Text from '@ruiyun/preact-text'

const SectionTitle = ({ children, slot }) => (
  <RowView
    height={67}
    padding={[0, 30, 0, 30]}
    bgColor='#f4f4f4'
    hAlign='between'
  >
    <Text size={26} color='#666'>
      {children}
    </Text>
    {slot && slot()}
  </RowView>
)

export default SectionTitle
