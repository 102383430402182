export const SEND_CODE = `mutation($mobile: String!) {
  result: sendVerifyCode(
    userType: PROPAGATOR
    mobile: $mobile
    stage: LOGIN 
  )
}`

export const LOGIN = `mutation($mobile: String!, $code: String!, $sessionId: String!) {
  result: bmsLoginWithVerifyCode(
    mobile: $mobile
    code: $code,
    sessionId: $sessionId
  )
}`

export const QUERY_ATTENDERS = `query($name: String, $type: String!, $meetingId: String!) {
  result: bmsAttenders(type: $type, meetingId: $meetingId, name: $name) {
    id
    name
    mobile
    attributes
  }
}
`
export const SAVE_ATTENDER = `mutation($attender: AddAttendersInput!, $meetingId: String!) {
  result: bmsAddAttenderH5(attender: $attender, meetingId: $meetingId)
}
`

export const CURRENT_USER = `query {
  result: currentUser {
    accountId
    cnName
    companyMobile
    companyEmail
    department {
      name
    }
    region: group(groupType: "region") {
      name
    }
    sector: group(groupType: "sector") {
      name
    }
    product: group(groupType: "product") {
      name
    }
    title: group(groupType:"title") {
      name
    }
    reportAccount {
      cnName
      companyMobile
    }
  }
}`

export const QUERY_MEETINGS = `query($search: PaginatedBmsMeetingSearchH5Input) {
  result: bmsMeetingsH5(search: $search) {
    items {
      id
      code
      name
      address
      startTime
      endTime
      city {
        name
      }
      status
      statusText
      hotel
      subStatus
      bmsBizStatus {
        text
        color
      }
      bmsMeetingHotel {
        name
        address
        position
        amapId
        city
      }
      budgets {
        items {
          id
          type
          value
          attributes
          orders {
            id
            type
            meetingId
            price
            attributes
            vendor {
              ... on CarServiceInfo {
                id
                serviceType
              }
            }
          }
        }
        total
      }
    }
    pager {
      currentPage
      pageSize
      total
    }
  }
}`
