import { h, Component, Fragment } from 'preact'
import AutoList from '@ruiyun/preact-m-auto-list'
import { router } from '@ruiyun/preact-m-router'
import SearchBar from '@ruiyun/preact-m-search-bar'
import Icon from '@ruiyun/preact-icon'
import { queryMeetings } from '../../services/MeetingService'

import ListItem from '../../components/MeetingListItem'
import { ColumnView } from '@ruiyun/preact-layout-suite'

const keyExtractor = (item) => item.id

const format = (ret) => {
  console.log(ret)
  return {
    list: ret.data.items,
    nomore: ret.data.pager.currentPage >= Math.ceil(ret.data.pager.total / 20),
  }
}

const renderItem = (props) => <ListItem {...props} />

export default class Meeting extends Component {
  state = {
    params: {
      keyword: '',
    },
  }

  componentDidMount() {
    router.setTitle('会议搜索')
  }

  onTabShow = () => {
    router.setTitle('会议搜索')
  }

  onSearch = (keyword) => {
    this.setState({
      params: Object.assign({}, this.state.params, {
        keyword,
      }),
    })
  }

  render() {
    return (
      <Fragment>
        <ColumnView
          width='100vw'
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 9 }}
        >
          <SearchBar
            bgColor='#fff'
            renderInputLeft={() => (
              <Icon name='iconsousuo' color='#9f9f9f' size={22} />
            )}
            onTextInput={this.onSearch}
            placeholder='输入会议名称搜索'
            textSize={26}
            bdr='0.1rem'
            inputBgColor='#f4f4f4'
          />
        </ColumnView>
        <ColumnView padding={[100, 0, 0, 0]}>
          <AutoList
            refreshable
            pageSize={20}
            params={this.state.params}
            fetchListData={queryMeetings}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            format={format}
          />
        </ColumnView>
      </Fragment>
    )
  }
}
